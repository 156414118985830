<template>
    <div class="page p-grid">
        <div class="p-col-12">
            <Breadcrumb :model="items"/>
        </div>
        <div class="p-col-12">
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-6">
                    <div>
                        <label>姓名:</label>
                        <InputText class="p-mt-2" placeholder=""/>
                    </div>
                </div>

                <div class="p-col-12 p-md-6">
                    <div>
                        <label>职位/职称:</label>
                        <MultiSelect class="p-mt-2" v-model="selectedPosition" :options="position" optionLabel="name" placeholder="选择职位" display="chip"/>
                    </div>
                </div>

                <div class="p-col-12 p-md-6">
                    <div>
                        <label>专业领域:</label>
                        <MultiSelect class="p-mt-2" v-model="selectedField" :options="field" optionLabel="name" placeholder="选择专业领域" display="chip"/>
                    </div>
                </div>

                <div class="p-col-12 p-md-6">
                    <div>
                        <label>获得奖项:</label>
                        <Dropdown class="p-mt-2" v-model="sendingPrize" :options="prize" optionLabel="name" placeholder="选择奖项名称"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div>
                <label>文本编辑器：</label>
                <div class="p-mt-2">
                    <Editor editorStyle="height: 320px"/>
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <label >风采展示/照片：</label>
            <div class="p-grid p-col p-mt-2">
                <FileUpload mode="basic" name="demo[]" url="./upload" accept="image/*" :maxFileSize="1000000" @upload="onUpload" :auto="true" chooseLabel="添加照片" />
                <Button style="width: 8%" class="p-ml-2 p-button-outlined" @click="goto()" label="浏览"/>
            </div>
        </div>
        <div class="p-grid p-col-12 p-mt-6 p-jc-around">
            <Button label="取消编辑" class="p-button-outlined" />
            <Button label="保存并发布" class="p-button-outlined" />
        </div>
    </div>
</template>

<script>
    export default {
        name: "",
        data() {
            return {
                selectedPosition: null,
                selectedField: null,
                sendingPrize: null,
                items:[
                    {label: '智库推送', to: '/assistExtension'},
                    {label: '协助推广', to: '/assistExtension'},
                    {label: '信息编辑', to: '/informationEditor'},
                ],
                position:[
                    {name: '中国委员会副经理', code: '1'},
                    {name: '常务委员会总经理', code: '2'},
                    {name: '中国服贸贸易协会董事长', code: '3'},
                    {name: '中国服贸贸易协会董事长', code: '4'},
                    {name: '中国服贸贸易协会董事长', code: '5'}
                ],
                field:[
                    {name: '政治学', code: '1'},
                    {name: '经济学', code: '2'},
                    {name: '社会经济学', code: '3'},
                    {name: '金融行业', code: '4'},
                    {name: '服装领域', code: '5'}
                ],
                prize:[
                    {name: '诺贝尔奖', code: '1'},
                    {name: '最佳男主角', code: '2'},
                    {name: '最佳女主角', code: '3'},
                    {name: '金马奖', code: '4'},
                    {name: '冠军奖', code: '5'}
                ],
            }
        },
        methods: {
            onUpload() {
                this.$toast.add({severity: 'info', summary: '成功', detail: '文件上传成功', life: 3000});
            },
            goto() {
                this.$router.push('/preview')
            }
        }
    }
</script>

<style scoped>

</style>